import * as React from "react";
import Head from "../../components/Head";
import BoothLayout from "../../components/boothLayout";
import { defaultStyles } from "../../libs/styles";
import { css } from "glamor";
import AppStore from "../../store/AppStore";
import RecordingBooth from "../../components/RecordingBooth";
const store = new AppStore();
store.recordingBooth.microphone.init();

css.global("body", {
  background: "black",
  height: "100%",
  width: "100%",
  padding: 0,
  margin: 0,
});

css.global("a", {
  ...defaultStyles.font.body,
  fontSize: "10px",
  textTransform: "uppercase",
  cursor: "pointer",
  textDecoration: "none",
  fontWeight: "bold",
});

css.global("html", {
  height: "100%",
  width: "100%",
  padding: 0,
  margin: 0,
});

// Scrollbar
css.global("::-webkit-scrollbar", {
  width: "10px",
  overflow: "overlay",
  display: "none",
});

// track
css.global("::-webkit-scrollbar-track", {
  background: "transparent",
});

// handle
css.global("::-webkit-scrollbar-thumb", {
  background: "transparent",
  borderRadius: "5px",
});

// handle on hover
css.global("::-webkit-scrollbar-thumb:hover", {
  background: "black",
});

css.global("::-webkit-scrollbar-corner", {
  background: "transparent",
});

export default function Home() {
  return (
    <BoothLayout>
      <Head
        title={"Stephani Moroni Bishop - Recording Booth"}
        keywords={[
          "engineer",
          "female",
          "senior",
          "UI",
          "react",
          "typescript",
          "OOP",
          "mobx",
          "observables",
          "css-in-js",
          "glamour",
          "emotion",
        ]}
      />
      <RecordingBooth store={store} />
    </BoothLayout>
  );
}
